export const en_analytics = {
  analytics: 'Analytics',
  selectAll: 'Select All',
  chats: 'Chats',
  visits: 'Visits',
  close: 'Close',
  print: 'Print',
  applyToAll: 'Apply to All',
  nonFilteredGraphs: 'Non-Filtered Graphs',
  noUsersAvailable: 'No users are available',
  noData_Available: 'No data Available',
  dataBeingFetched: 'Please wait, Data is being fetched...',
  lastHours: 'Last 24 Hours',
  lastDays: 'Last 30 Days',
  lastMonths: 'Last 6 Months',
  pleaseWait: 'Please wait, Data is being fetched...',
  addMore: 'Add More',
  customize: 'Customization',
  forThePastYear: 'For the Past Year',
  quickStatsLegendTextFor6Months:
    ' 6 months data comparison with previous 6 months is currently unavailable.',
  chatsLeads: 'Chats vs Leads',
  featuresUsage: 'Features Usage',
  chatReferrals: 'Chat Referrals',
  chatStatsBy: 'Chat Stats By',
  accountsActivation: 'Accounts Activation',
  topBottomLeadAccounts: 'Lead Accounts',
  topInactiveBillers: 'Top Inactive Billers',
  budgetPacing: 'Budget/Pacing',
  agentImages: 'Agent Images CTR',
  agentCTR: 'Agent CTR',
  accounts: 'Accounts',
  filters: 'Filters',
  clearAll: 'Clear All',
  clear: 'Clear',
  features: 'Features',
  devices: 'Devices',
  user: 'User',
  companyKey: 'Company Key(s)',
  typeOfBusiness: 'Types of business',
  ChartsShowHide: 'Charts Show/Hide',
  applyFilters: 'Apply Filters',
  apply: 'Apply',
  totalVisits: 'Total Visits',
  totalChats: 'Total Chats',
  totalLeads: 'Total Leads',
  totalBillableLeads: 'Total Billable Leads',
  clickThroughRate: 'Click Through Rate',
  leadConversionRate: 'Lead Conversion Rate',
  blankChats: 'Blank Chats',
  activeCallConnect: 'Active Call Connects',
  callConnectAttempts: 'Call Connect Attempts',
  callConnectDuration: 'Call Connect Duration (minutes)',
  activeChatsForHosts: 'Active Chats for Hosts',
  activeChats: 'Active Chats',
  ctr: 'CTR',
  lcr: 'LCR',
  topLeadGenerators: 'Top Lead Generators',
  topRevenuePerClient: 'Top Revenue Per Client',
  filterApplied: 'Filters Applied',
  quickStats: 'Quick Stats',
  charts: 'Charts',
  label1: 'Last 30 days',
  label2: 'Increase from previous 30 days',
  label3: 'Decrease from previous 30 days',
  label4: 'In the',
  label5: 'Percentage',
  label6: 'Increases from the',
  label7: 'Decreases from the',
  startTour: {
    label: 'Start tour'
  },
  tourIntro1: 'This is start of analytics page and its Quick stats widgets',
  tourIntro2: 'Graphs..',
  tourIntro3: 'Total visits..',
  tourIntro4: 'Total Chats..',
  tourIntro5: 'Total Leads..',
  tourIntro6: 'Total Billable Leads..',
  tourIntro7: 'Click Through Rate..',
  tourIntro8: 'Lead Conversion Rate..',
  tooltip1:
    'The below charts/graphs are not filtered by any available filters like feature, user, devices, etc.',
  filtersTooltip1: ' Changes in this section are applied to:',
  homeBannerHeading: 'Engage customers with Google Business Profiles',
  homeBannerTextDirect:
    'Get valuable contacts with Google Business Profiles by our live chat agents at Blazeo (formerly ApexChat).',
  homeBannerText:
    'Get valuable contacts with Google Business Profiles by our live chat agents. Please contact your account representative for more information.',
  learnMore: 'Learn More'
};
export default en_analytics;
export { default as fr_analytics } from '../fr-FR-AnalyticsDictionary/fr-analytics';
export { default as sp_analytics } from '../sp-SP-AnalyticsDictionary/sp-analytics';
