import * as Yup from 'yup';
import { errors } from 'services/utils/variables';

const urlRegx =
  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/g;
const nameRegx = /^(['a-zA-Z]+( [a-zA-Z]+)*){2,30}?$/g;
const nameNumberRegx = /^(['a-zA-Z0-9]+( [a-zA-Z0-9]+)*){2,30}?$/g;
export const emailRegx =
  /^(([^*#^'$\/<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailCommaSeperatedRegx =
  /^(([a-z0-9]+([-_.])?[a-z0-9])+(@){1}([a-z0-9]+([-])?[a-z0-9]+)+((\.)?[a-z]{2,4})+[\s]?[,]?[\s]?)+$/g;
const emailCommaSeperatedRegx2 =
  /^(([\w+\-.%]+@[\w\-.]+(\.[A-Za-z]{2,4}){1,3})+([\s]*[,]+[\s]*([\w+\-.%]+@[\w\-.]+(\.[A-Za-z]{2,4}){1,3})+)*)+$/g;
export const regexValidateExactPhone =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
export const regexValidatePhone = /\(?\b\s?\+?([0-9-.+( ))]{7,18})/;
export const regexValidateEmail =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
export const regexForNumberWithSomeCharacters = /^[0-9+-]+([-.+()]\d*)*?$/;
/**
 * Lookup for string email
 **/
export const email = Yup.string()
  .required(errors.empty)
  .matches(emailRegx, errors.notValid.email);

export const emailTextNotReq = Yup.string().matches(
  emailRegx,
  errors.notValid.email
);

export const multipleEmailTextNotReq = Yup.string().matches(
  emailCommaSeperatedRegx2,
  errors.notValid.email
);

export const requiredEmail = Yup.string()
  .required(errors.empty)
  .matches(emailRegx, errors.notValid.email);

/**
 * Lookup for string strong password with min 8 character
 **/
/* eslint-disable */
export const passwordMinChar = Yup.string()
  .required(errors.empty)
  .min(8, 'Minimum 8 character password required')
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    'Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special character (for example: #?!@$%^&*-)'
  );
/**
 * TO validate new password fild
 **/
export const newPassword = Yup.string()
  .required(errors.empty)
  .min(8, 'Minimum 8 character password required')
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    'Password must contain atleast 8 characters, one uppercase, one lowercase, one number and one special character'
  )
  .notOneOf(
    [Yup.ref('oldPassword')],
    'You cannot reuse your existing password'
  );
/**
 * TO match a password feild to confirm password feild
 **/
export const matchPassword = Yup.string()
  .required(errors.empty)
  .oneOf([Yup.ref('newPassword')], 'Passwords must match');
/**
 * Lookup for string strong password
 **/
export const password = Yup.string().required(errors.empty);

/**
 * Lookup for a generic text field string
 **/
export const requiredText = Yup.string().required(errors.empty);

export const requiredTextWithNoZero = errorMsg =>
  Yup.number()
    .required(errorMsg || errors.empty)
    .min(1, errorMsg || errors.empty);

export const requiredTextWithZeroToHundred = Yup.string()
  .required(errors.empty)
  .matches(/^[1-9][0-9]?$|^100$/, errors.range);

export const requiredNumber = Yup.number().required(errors.empty);

export const requiredArray = Yup.array()
  .of(
    Yup.object().shape({
      key: Yup.string(),
      value: Yup.string()
    })
  )
  .min(1, errors.empty)
  .required(errors.empty);

/**
 * Lookup for a website/ url field
 **/
export const urlText = Yup.string()
  .required(errors.empty)
  .matches(urlRegx, errors.notValid.url);

export const urlTextOnly = Yup.string().matches(urlRegx, errors.notValid.url);

/**
 * Lookup for a phone number field
 **/
export const phoneText = Yup.string()
  .max(17, errors.notValid.phoneLength)
  .matches(new RegExp(/^[ 0-9)(+-]/g), errors.notValid.phone);

export const phoneTextRequired = Yup.string()
  .required(errors.empty)
  .matches(new RegExp(/^[0-9]{1,15}$/g), errors.notValid.phone);
/**
 * Lookup for a zip field
 **/
export const zipText = Yup.string().matches(
  new RegExp(/^[0-9]{5}$/g),
  errors.notValid.zip
);

export const alphaNumericLength = Yup.string()
  .max(10, errors.notValid.zipLength)
  .matches(
    new RegExp(/^([A-Za-z0-9./+-])*$/),
    errors.notValid.specialCharacter
  );

export const alphaNumeric = Yup.string().matches(
  new RegExp(/^[ A-Za-z0-9./+-]*$/),
  errors.notValid.specialCharacter
);

export const onlyText = Yup.string().matches(
  new RegExp(/^[A-Za-z ]+$/g),
  errors.notValid.text
);

export const checkCustomDate = (data = '') =>
  data ? date.matches(new RegExp(/^[0-9\s\-\/\,]+/g)) : '';

export const requiredOnlyText = Yup.string()
  .required(errors.empty)
  .matches(new RegExp(/^[A-Za-z]+$/g), errors.notValid.text);

export const nameText = Yup.string()
  .required(errors.empty)
  .matches(nameRegx, errors.notValid.name);

export const nameTextWithNumbers = Yup.string()
  .required(errors.empty)
  .matches(nameNumberRegx, errors.notValid.name);

export const cityText = Yup.string().max(50, errors.notValid.cityLength);

export const emailText = Yup.string().matches(
  emailCommaSeperatedRegx,
  errors.notValid.email
);
export const consultationFee = Yup.string().max(
  128,
  errors.notValid.consultationFee
);
export const multipleEmailReqText = Yup.string()
  .required(errors.empty)
  .matches(emailCommaSeperatedRegx, errors.notValid.email);
export const emailReqText = Yup.string()
  .required(errors.empty)
  .matches(emailCommaSeperatedRegx2, errors.notValid.email);

export const isNumber = val => regexForNumberWithSomeCharacters.test(val);
